<template>
    <div class="col-md-11 indentText">
        <div class="row row1">
            <div class="details mt-3 mb-3">
                <h3 :title="task.task">
                    {{ trim_data(task.task) }}</h3>
                <sub class="break" v-if="task.description">{{taskDescription(task.description)}}</sub>
            </div>
            <div class="time-div col2">
                <h3 class="pull-right h3" title="Hours:Minutes" >{{min_to_hour(task.time_worked)}}</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import { min_to_hour } from '@/assets/js/utils'

    export default {
        name : "tasks",
        props : [ 'task' ],
        methods : {
            trim_data ( raw_data ) {
                return raw_data.length > 100 ? raw_data.slice ( 0, 100 ) + "..." : raw_data;
            },
            min_to_hour ( minutes ) {
                let time = min_to_hour ( minutes );
                let hrs = time.hrs;
                let mins = time.mins;
                if ( hrs <= 9 ) {

                    hrs = "0" + time.hrs
                }
                if ( mins <= 9 ) {
                    mins = "0" + time.mins
                }
                return hrs + ":" + mins
            },
            taskDescription ( description ) {
                return description.split ( '\n' ).map ( v => v.trim () ).filter ( v => v !== '' ).join ( '\n' );
            }

        }
    }
</script>

<style scoped>
    .row1 {
        /*border: 0px;*/
        /*padding-right: 0px !important;*/

        white-space     : nowrap;
        border-bottom   : 1px solid #D6E7FF;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
    }

    .col2 {
        padding-right : 0px !important;
    }

    .hand {
        width      : 70%;
        text-align : left;
    }

    .hand h3 {
        white-space : pre-wrap;
    }

    .time-div {
        padding      : 15px;
        padding-left : 0px !important;
    }

    h3 {
        margin-bottom : 0px;
        font-size     : medium;
        font-weight   : 400;
    }

    .details {
        white-space: initial;
        text-align: justify;
    }

    .col-md-11.indentText {
        max-width : 95% !important;
        flex      : 95% !important;
    }

    .indentText {
        margin-left : 5% !important;
    }

    @media (max-width : 367px) {
        .row1 h3 {
            font-size : 14px;
            /*margin-right: 10px;*/
        }
    }

    @media (max-width : 480px) {
        .time-div::before {
            content      : "";
            border-right : 1px solid #D6E7FF;
            padding      : 13px 0px;
            right        : 50px;
            top          : unset;
            position     : absolute;
        }
        .details {
            width: 100%;
        }
        .details h3 {
            font-size: 11px !important;
            font-weight: bold;
        }
    }

    @media (max-width : 320px) {
        .time-div::before {
            right : 39px;
        }
    }
</style>
